<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="4" class="m-b-5">
        <Select
          v-model="assetId"
          size="small"
          clearable
          filterable
          placeholder="选择所属资产，可搜索"
          @on-change="onChangeAsset"
        >
          <Option
            v-for="item in assetArray"
            :key="'asset_' + item.id"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4" class="m-b-5">
        <Select
          v-model="query.stationId"
          size="small"
          clearable
          filterable
          placeholder="选择所属站点，可搜索"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_' + item.id"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4" class="m-b-5">
        <Select
          v-model="query.productId"
          size="small"
          clearable
          filterable
          placeholder="选择所属产品，可搜索"
        >
          <Option
            v-for="item in productArray"
            :key="'product_' + item.dictKey"
            :value="Number(item.dictKey)"
          >{{ item.dictValue }}</Option>
        </Select>
      </i-col>
      <i-col span="4" class="m-b-5">
        <DatePicker
          placeholder="选择发布档期范围"
          size="small"
          v-model="dateRage"
          type="daterange"
          split-panels
          style="width:100%"
          :editable="false"
          clearable
          @on-change="onChangeDate"
        ></DatePicker>
      </i-col>
      <i-col span="4" class="m-b-5">
        <Select
          v-model="query.industryId"
          size="small"
          clearable
          filterable
          placeholder="选择所属行业，可搜索"
        >
          <Option
            v-for="item in industryArray"
            :key="'industry_' + item.id"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4"  class="m-b-5">
        <Select
          v-model="query.status"
          size="small"
          clearable
          placeholder="选择状态"
        >
          <Option
            v-for="item in statusArray"
            :key="'sataus_' + item.value"
            :value="item.value"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4"  class="m-b-5">
        <Select
          v-model="query.isClassicCase"
          size="small"
          clearable
          placeholder="是否精选"
        >
          <Option :value="1">是</Option>
          <Option :value="0">否</Option>
        </Select>
      </i-col>
      <i-col span="4"  class="m-b-5">
        <i-input
          v-model="query.keyword"
          size="small"
          placeholder="关键字：案例名称或站点名称"
        ></i-input>
      </i-col>
      <i-col span="16">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          class="m-r-5 "
          @click="handleSearch"
        >搜索</Button>
        <Button
          type="success"
          size="small"
          class="m-r-5"
          @click="onAdd"
        >新增案例</Button>
        <Button
          type="primary"
          size="small"
          class="m-r-5"
          @click="onShowStat"
        >案例统计</Button>
        <Button
          type="primary"
          size="small"
          class="m-r-5"
          @click="onShowBehavior"
        >行为分析</Button>
        <Button
          type="primary"
          size="small"
          class="m-r-5"
          @click="onViewMessage"
        >查看客户留言</Button>
        <Button
          type="primary"
          size="small"
          @click="onViewBanner"
        >BANNER管理</Button>
      </i-col>
    </Row>
    <div class="m-b-10">
      <Button
        type="error"
        size="small"
        class="m-r-5"
        :disabled="selectedIds.length===0"
        @click="onBatchDel"
      >批量删除</Button>
    </div>
    <Table
      stripe
      size="small"
      :data="list"
      :columns="tableColumns"
      :loading="tableLoading"
      @on-selection-change="handleSelection"
    >
    </Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        show-sizer
        :current="query.pageNumber"
        @on-change="handleChangePage"
        :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'
      ></Page>
    </div>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >预览案例 </p>
      <Row class="m-b-5">
        <i-col class="text-right">
          <Button
            size="small"
            type="success"
            @click="viewBigImage"
          >查看原图</Button>
        </i-col>
      </Row>
      <img-light-box
        ref="imgLightBox"
        :showModal="visiblePreview"
        :sortIndex.sync="sortIndex"
      ></img-light-box>
    </Modal>
    <Modify
      ref="modify"
      :newAdd="newAdd"
      :industryData="industryArray"
      :assetData="assetArray"
      :productData="productArray"
      @refreshData="getTableData"
    />
  </div>
</template>

<script>
import { getMaterialLibraryPage, updateStatus, deleteLibraries, updateClassicStatus } from '@/api/msp/materiallibrary'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { caseLibraryMixins } from '../mixins'
import { sysMixins } from '@/assets/mixins/sys'

import Modify from './Modify.vue'
import ImgLightBox from '@/components/common/ImgVideoLightBox'

export default {
  mixins: [caseLibraryMixins, sysMixins],
  components: {
    Modify,
    ImgLightBox

  },
  data () {
    return {
      pagesizeSetting: [15, 50, 100, 200, 500],
      assetId: undefined,
      dateRage: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: '',
        industryId: undefined,
        keyword: '',
        productId: undefined,
        stationId: undefined,
        status: undefined,
        isClassicCase: undefined
      },
      newAdd: false,
      tableLoading: false,
      list: [],
      total: 0,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '#', align: 'center', key: 'id' },
        { title: '名称', align: 'center', key: 'name' },
        { title: '行业', align: 'center', key: 'industryName' },
        { title: '产品', align: 'center', key: 'productName' },
        {
          title: '发布档期',
          align: 'center',
          render: (h, { row }) => {
            return h('span', GetCurrentSchedule(row.startDate, row.endDate, false))
          }
        },
        {
          title: '覆盖站点',
          align: 'center',
          render: (h, { row }) => {
            const html = []
            row.stationVOList.forEach(item => {
              html.push(h('p', `${item.assetName} - ${item.stationName}`))
            })
            return h('div', html)
          }
        },
        {
          title: '状态',
          align: 'center',
          key: 'statusName',
          render: (h, { row }) => {
            const status = this.statusArray.find(x => x.value === row.status)
            const color = status ? status.color : 'default'
            return h('Tag', {
              props: { color }
            }, row.statusName)
          }
        },
        { title: '点击量', align: 'center', key: 'pv' },
        { title: '分享次数', align: 'center', key: 'shareCount' },
        { title: '创建时间', align: 'center', key: 'createTime' },
        {
          title: '是否精选',
          align: 'center',
          render: (h, { row }) => {
            return h('i-switch', {
              props: {
                value: row.isClassicCase
              },
              scopedSlots: {
                open: props => h('span', '是'),
                close: props => h('span', '否')
              },
              on: {
                'on-change': () => {
                  this.onChangeClassic(row)
                }
              }

            })
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            const html = []
            html.push(h('a', {
              class: ['m-r-5'],
              on: {
                click: () => {
                  this.onViewCase(row)
                }
              }
            }, '预览案例'),
            h('a', {
              class: ['m-r-5'],
              on: {
                click: () => {
                  this.onEdit(row)
                }
              }
            }, '编辑')
            )
            // if (row.status === 0) {
            //   html.push(
            //     h('a', {
            //       class: ['m-r-5'],
            //       on: {
            //         click: () => {
            //           this.onUnmount(row.id, 1)
            //         }
            //       }
            //     }, '上架')
            //   )
            // }

            if (row.status === 1) {
              html.push(h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.onUnmount(row.id, 2)
                  }
                }
              }, '下架'))
            } else {
              html.push(
                h('a', {
                  class: ['m-r-5'],
                  on: {
                    click: () => {
                      this.onUnmount(row.id, 1)
                    }
                  }
                }, '上架')
              )
            }

            return h('div', html)
          }
        }
      ],
      visiblePreview: false,
      fileName: '',
      sortIndex: 0,
      selectedIds: []
    }
  },
  created () {
    this.getAssetData()
    // this.getCategoryData()
    this.getPublisherProductData()
    this.getIndustryData()
    this.getTableData()
  },
  mounted () {
    // 设置头部路由标签
    this.setLinkTagArray({ key: 'caseLibraryList', value: '案例列表' })
    this.setLinkRouterMaps(['caseLibraryList', () => {
      this.setShowLeftBlock(false)
      this.setShowMap(false)
      this.setShowRightBlock(false)
      this.setLeftComponent('')
      this.setRightComponent('')
      this.setBottomComponent('IndexTable')
    }])
    this.setActivedTagName('caseLibraryList')
  },
  methods: {
    getTableData () {
      this.tableLoading = true
      getMaterialLibraryPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.list.forEach(item => {
            // 禁用上架案例
            item._disabled = item.status === 1
          })
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    handleSearch () {
      this.selectedIds = []
      this.query.pageNumber = 1
      this.getTableData()
    },
    handleChangePage (curPage) {
      this.selectedIds = []
      this.query.pageNumber = curPage
      this.getTableData()
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.getTableData()
    },
    onChangeAsset () {
      if (!this.assetId) {
        this.query.stationId = undefined
      }
      const assetIds = this.assetId ? JSON.stringify([this.assetId]) : ''
      this.getStationByAssetIds(assetIds)
    },
    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
    },
    onViewCase (params) {
      const imgList = params.attachVOList.length === 0 ? [] : params.attachVOList.map(x => {
        return Object.assign(x, { fileKey: x.filePath })
      })
      const postData = {
        startIndex: 0,
        imgList
      }
      this.sortIndex = 0
      this.visiblePreview = true
      this.$refs.imgLightBox.init(postData)
    },
    onAdd () {
      this.newAdd = true
      this.$nextTick(() => {
        this.$refs.modify.init()
      })
    },
    handleSelection (selections) {
      this.selectedIds = selections.map(x => x.id)
    },
    onBatchDel () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除已选择项？',
        onOk: () => {
          deleteLibraries({ libraryIds: JSON.stringify(this.selectedIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.selectedIds = []
              this.getTableData()
            }
          })
        }
      })
    },
    onEdit (params) {
      this.newAdd = false
      this.$nextTick(() => {
        this.$refs.modify.init(params)
      })
    },
    /**
     * 上下架
     * @param {Number} status
     */
    onUnmount (id, status) {
      this.$Modal.confirm({
        title: '操作提示',
        content: `确定要${status === 1 ? '上架' : '下架'}此案例库？`,
        onOk: () => {
          updateStatus({ libraryId: id, status: status }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getTableData()
            }
          })
        }
      })
    },
    /**
     * 更新案例是否为精选
     */
    onChangeClassic (params) {
      const postData = {
        isClassicCase: !params.isClassicCase,
        libraryId: params.id
      }
      updateClassicStatus(postData).then(res => {
        if (res && res.success) {
          this.$Notice.success({ desc: '操作成功' })
          this.getTableData()
        }
      })
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    },
    onShowStat () {
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'caseLibraryStat', value: '案例统计' })
      this.setLinkRouterMaps(['caseLibraryStat', () => {
        this.setShowLeftBlock(false)
        this.setShowMap(false)
        this.setShowRightBlock(false)
        this.setLeftComponent('')
        this.setRightComponent('')
        this.setBottomComponent('Statistics')
      }])
      this.setActivedTagName('caseLibraryStat')
    },
    /**
     * 查看行为分析
     */
    onShowBehavior () {
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'caseLibraryBehavior', value: '行为分析' })
      this.setLinkRouterMaps(['caseLibraryBehavior', () => {
        this.setShowLeftBlock(false)
        this.setShowMap(false)
        this.setShowRightBlock(false)
        this.setLeftComponent('')
        this.setRightComponent('')
        this.setBottomComponent('BehaviorAnalysis')
      }])
      this.setActivedTagName('caseLibraryBehavior')
    },
    /**
     * 查看客户留言
     */
    onViewMessage () {
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'caseLibraryCustomerMsg', value: '客户留言' })
      this.setLinkRouterMaps(['caseLibraryCustomerMsg', () => {
        this.setShowLeftBlock(false)
        this.setShowMap(false)
        this.setShowRightBlock(false)
        this.setLeftComponent('')
        this.setRightComponent('')
        this.setBottomComponent('CustomerMsg')
      }])
      this.setActivedTagName('caseLibraryCustomerMsg')
    },
    /**
     * banner图片管理
     */
    onViewBanner () {
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'bannerManage', value: 'BANNER管理' })
      this.setLinkRouterMaps(['bannerManage', () => {
        this.setShowLeftBlock(false)
        this.setShowMap(false)
        this.setShowRightBlock(false)
        this.setLeftComponent('')
        this.setRightComponent('')
        this.setBottomComponent('bannerManage')
      }])
      this.setActivedTagName('bannerManage')
    }

  }
  // watch: {
  //   productArray: {
  //     deep: true,
  //     immediate: true,
  //     handler (val) {
  //       if (val && val.length) {
  //         this.query.productId = val[0].id
  //       }
  //     }
  //   }
  // }
}
</script>
